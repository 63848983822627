<template>
  <form @submit.prevent="addDelegate">
    <modal-card :title="'Add delegate'" :processing="processing">
      <section>
        <p class="has-margin-bottom-100">
          Enter the email address of the user you'd like to grant site access
          to. Please note, this user must already have a fixed.net account.
        </p>

        <div>
          <b-field label="Email">
            <b-input
              ref="email"
              v-model="form.email"
              :disabled="processing"
              required
              type="email"
              placeholder="Delegate email"
            />
          </b-field>
        </div>
      </section>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Add delegate
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "AddDelegateModal",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      form: {
        email: ""
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.$v.isEmail(this.form.email)) return false;
      return true;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.email.$el.querySelector("input").focus();
    });
  },
  methods: {
    addDelegate() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch("sites/addDelegate", {
          siteId: this.siteId,
          email: this.form.email
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
